import TablePage from '@/found/components/table_page';
import Modal from '@/found/components/modal';

export default {
  extends: TablePage,
  props: {
    treeCode: String,
    treeName: String,
  },
  data() {
    return {
      // requestUrl: '/fee/feePoolOperationController/findFeePoolOperationPageList',
      params: {
        directSystemId: '',
        directSystemName: '',
      },
      configs: {
        formConfig: {
          item: [
            {
              field: 'operationType',
              methods: 'get',
              search: 'true',
              title: '操作类型',
              type: 'select',
              apiUrl: '/fee/feePoolController/getOperationTypeSelectList',
              paramsFixed: {
                queryType: 8,
              },
              optionsKey: {
                label: 'text',
                value: 'value',
              },
            },
          ],
        },
      },
      modalConfig: {
        title: '新增',
        visible: false,
        type: '',
      },
    };
  },
  components: {
    Modal,
  },
  watch: {
    treeCode(val) {
      this.params.directSystemId = val;
      this.params.directSystemName = this.treeName;
      this.getList();
    },
  },
  created() {
    this.getConfigList('fee_pool_operation_list');
  },
  methods: {
    setColumn(v) {
      const rowData = v;
      if (rowData.field === 'fileList') {
        rowData.type = 'html';
        rowData.formatter = ({ cellValue }) => {
          if (cellValue && cellValue.length > 0) {
            const list = [];
            cellValue.forEach((val) => {
              list.push(`<a href=${val.url}>${val.fileName}</a>`);
            });
            return list.join(',');
          }
          return '';
        };
      }
      return rowData;
    },
    beforeModalClick({ val }) {
      return true;
    },
    modalClick({ val, row }) {},
  },
};
